import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import {useAuth} from "../AuthUser";
import { useNavigate } from "react-router-dom";
import LogoIcon from "./../assets/img/logo2.png";

function Navigation({ hideAuthButton = false }) {
	const navigate = useNavigate();
	const auth = useAuth();

	const AuthButton = () => {
		if (!hideAuthButton) {
			if (!auth.user) {
				return <Button variant="outline-secondary" size="" onClick={() => navigate("/signin", {replace: true})}>Member Login</Button>;
			}
		}
	};

	return (
		<>
			<Navbar variant="dark" className="sticky-top bg-dark-blue" expand="lg" style={{minHeight: '68px'}}>
				<Container>
					<Navbar.Brand href="/" style={{letterSpacing: '1px'}} className="monument-font">
					MYGOLF<span className="text-secondary">DNA</span>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<Nav.Link href="/#how-it-works">How It Works</Nav.Link>
							<Nav.Link href="/#your-teacher">Your Teacher</Nav.Link>
							<Nav.Link href="/#testimonials">Testimonials</Nav.Link>
							<Nav.Link href="/pricing">Get Access</Nav.Link>
						</Nav>
						<Nav className="d-flex">
							<AuthButton/>
							{auth.user && <Nav.Link href="/dna-profile"><i className="bi bi-person-circle me-2" style={{fontSize: "1.5rem"}}/></Nav.Link>}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default Navigation;
