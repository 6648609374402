import {useAuth} from "../AuthUser";
import {Navigate, useNavigate} from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Button from "react-bootstrap/Button";
import API from "../api";
import {useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import LoadingDiv from "../components/LoadingDiv";
import Card from "react-bootstrap/Card";

function MyLessons() {
	let auth = useAuth();
	let navigate = useNavigate();

	const canSubmitLesson = auth.user.canSubmitLesson;
	const lessonCountRemaining = auth.user.lessonCountRemaining || 0;
	const [lessons, setLessons] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true);
		API.get("reviews")
			.then(response => setLessons(response.data))
			.finally(() => setLoading(false));
	}, [])

	const onRowSelect = (event) => {
		if (event.data.status === "complete") {
			navigate(`/lesson/${event.data.id}`)
		}
	};

	const statusTemplate = (lesson) => {
		if (lesson.status === "complete") {
			return <Badge bg="success">Completed</Badge>
		}
			return <Badge bg="danger">Pending</Badge>
	}

	const downloadLinkTemplate = (lesson) => {
		return lesson.reviewVideos.map(findInstructorLesson);
	}

	const createdDateTemplate = (video) => {
		return moment.utc(video.created_at, 'YYYY-MM-DD').format('ll');
	}

	function findInstructorLesson(video, index) {
		if (video.type === "instructor") return 'Watch Now';
	}

	return (
		<>
		<Card className="lesson-card">
			<Card.Header as="h6">
				<div className="d-none d-md-block">
				<div className="hstack gap-sm-3 gap-1">
					<div className="">My Lessons</div>
					<div className="ms-auto small">{auth.user.planType === 'pro' ? 'Unlimited' : lessonCountRemaining} Lesson(s) Left</div>
					<div className="vr"/>
					<div className=""><Button href="/upload-videos" variant="success" size="sm" disabled={!canSubmitLesson}>Start New Lesson</Button></div>
				</div>
				</div>
				<div className="d-md-none">
				<div className="hstack gap-5">
					<div className="">
						My Lessons
						<div className="text-black-50" style={{fontSize: 12}}>{auth.user.planType === 'pro' ? 'Unlimited' : lessonCountRemaining} Lesson(s) Left</div>
					</div>
					<div className="ms-auto"><Button href="/upload-videos" variant="success" size="sm" disabled={!canSubmitLesson}>Start New Lesson</Button></div>
				</div>
				</div>
			</Card.Header>
			<Card.Body style={{padding: 0, height: '300px', overflowY: 'scroll'}}>
				{isLoading ? <LoadingDiv/> : !auth.user.planType && !lessons.length && !lessonCountRemaining ?
					<div className="p-3"> <Alert variant="danger">You must be a premium member to submit lessons. <a href="/pricing">GET ACCESS
						NOW</a></Alert></div> : (
						<>
							<DataTable
								style={{fontSize: '14px'}}
								value={lessons}
								rows={3}
								//rowsPerPageOptions={[10, 25, 50]}
								//tableStyle={{minWidth: '50rem'}}
								selectionMode="single"
								onRowSelect={onRowSelect}
							>
								<Column field="status" header="Status" body={statusTemplate}/>
								<Column field="created_at" header="Submitted" body={createdDateTemplate}/>
								<Column header="Download" body={downloadLinkTemplate}/>
							</DataTable>
						</>
					)}
			</Card.Body>
		</Card>
		</>
	);
}

export default MyLessons;
