import * as React from "react";
import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

import {AuthProvider} from "./AuthUser";
import RequireAuth from "./RequireAuth";

import Home from "./Home";
import DNAProfile from "./DNAProfile";
import Pricing from "./Pricing";

import HomeLayout from "./layouts/HomeLayout";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import Signup from "./pages/Auth/Signup";
import Signin from "./pages/Auth/Signin";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Premier from "./pages/Subscriptions/Premier";
import PremierPlus from "./pages/Subscriptions/PremierPlus";
import Pro from "./pages/Subscriptions/Pro";
import SubscriptionLayout from "./layouts/SubscriptionLayout";
import PricingLayout from "./layouts/PricingLayout";
import UploadVideos from "./pages/UploadVideos";
import Account from "./pages/Account";
import Shipping from "./pages/Wizard/Shipping";
import About from "./pages/Wizard/About";
import About2 from "./pages/Wizard/About2";
import Upload from "./pages/Wizard/Upload";
import Consult from "./pages/Wizard/Consult";
import ResetPassword from "./pages/Auth/PasswordReset";
import Privacy from "./pages/Policies/Privacy";
import Thankyou from "./pages/Subscriptions/Thankyou";
import Basic from "./pages/Subscriptions/Basic";
import InstructorLessons from "./pages/Admin/Lessons";
import InstructorLesson from "./pages/Admin/UploadVideos";
import AdminLayout from "./layouts/AdminLayout";
import Video from "./pages/Videos/Video";
import List from "./pages/Videos/List";
import CreateVideo from "./pages/Admin/CreateVideo";
import VideosList from "./pages/Admin/Videos";
import EditVideo from "./pages/Admin/EditVideo";
import CreateCategory from "./pages/Admin/CreateCategory";
import EditCategory from "./pages/Admin/EditCategory";
import CategoryList from "./pages/Admin/Categories";
import MyLessons from "./pages/MyLessons";
import ProgressTracker from "./pages/ProgressTracker";
import Lesson from "./pages/Lesson";
import LessonComments from "./pages/Admin/LessonComments";
import AdminUsers from "./pages/Admin/Users";
import AdminUser from "./pages/Admin/User";
import EditUser from "./pages/Admin/EditUser";
import CreateUser from "./pages/Admin/CreateUser";
import CreateLesson from "./pages/Admin/CreateLesson";
import Cancel from "./pages/Subscriptions/Cancel";
import CancelReally from "./pages/Subscriptions/CancelReally";
import UpdatePayment from "./pages/Subscriptions/UpdatePayment";
import Plan from "./pages/Subscriptions/Plan";
import InstructorPricing from "./InstructorPricing";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route element={<HomeLayout />}>
          <Route path="/policies/privacy" element={<Privacy />} />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route
            path="/account"
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          />
          <Route
            path="/dna-profile"
            element={
              <RequireAuth>
                <DNAProfile />
              </RequireAuth>
            }
          >
            <Route index element={<MyLessons />}/>
            <Route
              path="progress-tracker"
              element={<ProgressTracker />}
            />
          </Route>
          <Route
            path="/lesson/:lessonId"
            element={
              <RequireAuth>
                <Lesson />
              </RequireAuth>
            }
          />
          <Route
            path="/upload-videos"
            element={
              <RequireAuth>
                <UploadVideos />
              </RequireAuth>
            }
          />
          <Route
            path="/videos/"
            element={
              <RequireAuth>
                <List />
              </RequireAuth>
            }
          />
          <Route
            path="/videos/:slug"
            element={
            <RequireAuth>
                <Video />
            </RequireAuth>
            }
          />
        </Route>

        <Route element={<AdminLayout />}>
          <Route
            path="/admin/lessons"
            element={
              <RequireAuth>
                <InstructorLessons />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/users"
            element={
              <RequireAuth>
                <AdminUsers />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/users/:userId"
            element={
              <RequireAuth>
                <AdminUser />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/users/edit/:userId"
            element={
              <RequireAuth>
                <EditUser />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/users/create"
            element={
              <RequireAuth>
                <CreateUser />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/users/:userId/createLesson"
            element={
              <RequireAuth>
                <CreateLesson />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/lessons/:lessonId"
            element={
              <RequireAuth>
                <InstructorLesson />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/videos"
            element={
              <RequireAuth>
                <VideosList />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/videos/create"
            element={
              <RequireAuth>
                <CreateVideo />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/videos/:videoId"
            element={
              <RequireAuth>
                <EditVideo />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/categories"
            element={
              <RequireAuth>
                <CategoryList />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/category/create"
            element={
              <RequireAuth>
                <CreateCategory />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/category/:categoryId"
            element={
              <RequireAuth>
                <EditCategory />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/lessonComments"
            element={
              <RequireAuth>
                <LessonComments />
              </RequireAuth>
            }
          />
        </Route>

        <Route element={<SubscriptionLayout/>}>
          <Route
            path="/thankyou"
            element={
              <RequireAuth>
                <Thankyou />
              </RequireAuth>
            }
          />
          <Route
            path="/cancel"
            element={
              <RequireAuth>
                <Cancel />
              </RequireAuth>
            }
          />
          <Route
            path="/update-payment"
            element={
              <RequireAuth>
                <UpdatePayment />
              </RequireAuth>
            }
          />
          <Route
            path="/cancel-sub"
            element={
              <RequireAuth>
                <CancelReally />
              </RequireAuth>
            }
          />
          <Route
            path="/wizard/shipping"
            element={
              <RequireAuth>
                <Shipping />
              </RequireAuth>
            }
          />
          <Route
            path="/wizard/about"
            element={
              <RequireAuth>
                <About />
              </RequireAuth>
            }
          />
          <Route
            path="/wizard/about2"
            element={
              <RequireAuth>
                <About2 />
              </RequireAuth>
            }
          />
          <Route
            path="/wizard/upload"
            element={
              <RequireAuth>
                <Upload />
              </RequireAuth>
            }
          />
          <Route
            path="/wizard/consult"
            element={
              <RequireAuth>
                <Consult />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<SubscriptionLayout />}>
          <Route path="/premier" element={<Premier />} />
          <Route path="/premier-plus" element={<PremierPlus />} />
          <Route path="/pro" element={<Pro />} />
          <Route path="/basic" element={<Basic />} />
          <Route path="/plans/:planId" element={<Plan />} />
        </Route>
        <Route element={<PricingLayout backgroundColor="#1B2631" />}>
            <Route path="/pricing" element={<Navigate replace to="/pricing/instructor/15" />} />
          <Route path="/pricing/instructor/:instructorId" element={<InstructorPricing />} />
        </Route>
        <Route element={<AuthLayout backgroundColor="#1B2631" />}>
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
        <Route element={<HomeLayout/>} >
          <Route path='*' element={<Home />}/>
        </Route>
      </Routes>
    </AuthProvider>
  );
}
