import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
	Container,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
import VideoUpload from "../VideoUpload";
import {useNavigate} from "react-router-dom";

const About = () => {
	const { setUser, user } = useAuth();
	const navigate = useNavigate();

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}

	const getData = () => user || { firstName: "", lastName: "", email: "", birthday: "" };

	return (
	<>
		<Helmet>
			<title>Build Your DNA Profile | mygolfdna.com</title>
			<meta name="description" content="build your dna profile"/>
		</Helmet>
		<div className="mt-4">
			<Container>
				<Row>
					<div className="text-center h1 text-uppercase monument-font">Build Your DNA Profile</div>
					<Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
						<p className="text-center text-black-50">Take five minutes and tell us about you and your game, show us some swings. Piece of cake!</p>
						<div className="container d-flex justify-content-center align-items-center">

							<div className="progresses">


								<div className="steps">
									<span className="font-weight-bold">1</span>
								</div>
								<div className="small d-none d-sm-block">About</div>


								<span className="line"/>

								<div className="steps">
									<span className="font-weight-bold">2</span>
								</div>
								<div className="small d-none d-sm-block">Upload</div>
								<span className="line"/>

								<div className="steps">
									<span className="font-weight-bold">3</span>
								</div>
								<div className="small d-none d-sm-block">Consult</div>

							</div>

						</div>

					<p className="mt-4 text-center text-secondary font-weight-bold">Rate your game in each area</p>
						<Formik
							enableReinitialize={true}
							initialValues={{
								driverAccuracy: '',
								driverDistance: '',
								fairwaysWoodsHybrids: '',
								ironPlay: '',
								chipping: '',
								pitching: '',
								bunkerPlay: '',
								putting: '',
								courseManagement: '',
						}}

							validate={(values) => {
								const errors = {};
								console.log(values)
								if (!values.driverAccuracy) {
									errors.driverAccuracy = "Required";
								}
								if (!values.driverDistance) {
									errors.driverDistance = "Required";
								}
								if (!values.fairwaysWoodsHybrids) {
									errors.fairwaysWoodsHybrids = "Required";
								}

								if (!values.ironPlay) {
									errors.ironPlay = "Required";
								}
								if (!values.chipping) {
									errors.chipping = "Required";
								}
								if (!values.pitching) {
									errors.pitching = "Required";
								}
								if (!values.bunkerPlay) {
									errors.bunkerPlay = "Required";
								}
								if (!values.putting) {
									errors.putting = "Required";
								}
								if (!values.courseManagement) {
									errors.courseManagement = "Required";
								}

								return errors;
							}}
							onSubmit={(values, { setSubmitting, setErrors }) => {
								setTimeout(() => {
									API.put("wizard", {
										driverAccuracy: values.driverAccuracy,
										driverDistance: values.driverDistance,
										ironPlay: values.ironPlay,
										chipping: values.chipping,
										pitching: values.pitching,
										bunkerPlay: values.bunkerPlay,
										putting: values.putting,
										courseManagement: values.courseManagement,
										fairwaysWoodsHybrids: values.fairwaysWoodsHybrids,
									})
										.then((res) => {
											navigate('/wizard/about2', { replace: true })
											toast.success('Almost done I promise...', {
												position: toast.POSITION.TOP_RIGHT,
											});
											setSubmitting(false);
										})
										.catch((error) => {
											setSubmitting(false);
											if (error.response) {
												notify(error.response.data.message);
											}
											if (
												error.response &&
												error.response.data.errors &&
												error.response.data.errors.length
											) {
												const result = error.response.data.errors.reduce(function (
														map,
														obj
													) {
														map[obj.property] = obj.message;
														return map;
													},
													{});
												setErrors(result);
											}
										});
								}, 400);
							}}
						>
							{({
									values,
									errors,
									touched,
									handleChange,
									handleSubmit,
									isSubmitting,
									setFieldValue,
								}) => (
								<>

											<Form noValidate className="form-group" onSubmit={handleSubmit}>
												<Row>
													<Col sm={12} md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="driverAccuracy"
																name="driverAccuracy"
																id="driverAccuracy"
																onChange={handleChange}
																value={values.driverAccuracy}
																isInvalid={errors.driverAccuracy && touched.driverAccuracy}
																isValid={!errors.driverAccuracy && touched.driverAccuracy}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="driverAccuracy">Driver Accuracy</Form.Label>
															<div className="invalid-feedback">{errors.driverAccuracy}</div>
														</Form.Group>
													</Col>
														<Col sm={12} md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="driverDistance"
																name="driverDistance"
																id="driverDistance"
																onChange={handleChange}
																value={values.driverDistance}
																isInvalid={errors.driverDistance && touched.driverDistance}
																isValid={!errors.driverDistance && touched.driverDistance}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="driverDistance">Driver Distance</Form.Label>
															<div className="invalid-feedback">{errors.driverDistance}</div>
														</Form.Group>
														</Col>

													<Col sm={12} md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="ironPlay"
																name="ironPlay"
																id="ironPlay"
																onChange={handleChange}
																value={values.ironPlay}
																isInvalid={errors.ironPlay && touched.ironPlay}
																isValid={!errors.ironPlay && touched.ironPlay}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="ironPlay">Iron Play</Form.Label>
															<div className="invalid-feedback">{errors.ironPlay}</div>
														</Form.Group>
													</Col>
													<Col sm={12} md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="chipping"
																name="chipping"
																id="chipping"
																onChange={handleChange}
																value={values.chipping}
																isInvalid={errors.chipping && touched.chipping}
																isValid={!errors.chipping && touched.chipping}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="chipping">Chipping</Form.Label>
															<div className="invalid-feedback">{errors.chipping}</div>
														</Form.Group>
													</Col>
													<Col sm={12} md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="pitching"
																name="pitching"
																id="pitching"
																onChange={handleChange}
																value={values.pitching}
																isInvalid={errors.pitching && touched.pitching}
																isValid={!errors.pitching && touched.pitching}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="pitching">Pitching</Form.Label>
															<div className="invalid-feedback">{errors.pitching}</div>
														</Form.Group>
													</Col>
													<Col sm={12} md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="bunkerPlay"
																name="bunkerPlay"
																id="bunkerPlay"
																onChange={handleChange}
																value={values.bunkerPlay}
																isInvalid={errors.bunkerPlay && touched.bunkerPlay}
																isValid={!errors.bunkerPlay && touched.bunkerPlay}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="bunkerPlay">Bunker Play</Form.Label>
															<div className="invalid-feedback">{errors.bunkerPlay}</div>
														</Form.Group>
													</Col>
													<Col sm={12} md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="putting"
																name="putting"
																id="putting"
																onChange={handleChange}
																value={values.putting}
																isInvalid={errors.putting && touched.putting}
																isValid={!errors.putting && touched.putting}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="putting">Putting</Form.Label>
															<div className="invalid-feedback">{errors.putting}</div>
														</Form.Group>
													</Col>
													<Col sm={12} md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="courseManagement"
																name="courseManagement"
																id="courseManagement"
																onChange={handleChange}
																value={values.courseManagement}
																isInvalid={errors.courseManagement && touched.courseManagement}
																isValid={!errors.courseManagement && touched.courseManagement}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="courseManagement">Course Management</Form.Label>
															<div className="invalid-feedback">{errors.courseManagement}</div>
														</Form.Group>
													</Col>
													<Col sm={12} md={12}>
														<Form.Group className="form-floating mb-3">
															<Form.Select
																aria-label="fairwaysWoodsHybrids"
																name="fairwaysWoodsHybrids"
																id="fairwaysWoodsHybrids"
																onChange={handleChange}
																value={values.fairwaysWoodsHybrids}
																isInvalid={errors.fairwaysWoodsHybrids && touched.fairwaysWoodsHybrids}
																isValid={!errors.fairwaysWoodsHybrids && touched.fairwaysWoodsHybrids}
															>
																<option value="" disabled>Select your option</option>
																<option value="1">1 - Poor</option>
																<option value="2">2 - Unsatisfactory</option>
																<option value="3">3 - Satisfactory</option>
																<option value="4">4 - Very Satisfactory</option>
																<option value="5">5 - Outstanding</option>
															</Form.Select>

															<Form.Label className="" htmlFor="fairwaysWoodsHybrids">Fairways, Woods & Hybrids</Form.Label>
															<div className="invalid-feedback">{errors.fairwaysWoodsHybrids}</div>
														</Form.Group>
													</Col>
												</Row>
												<div className="d-grid">
													<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
														{isSubmitting ? (
															<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
															</>
														) : (
															"Next Step"
														)}
													</Button>
												</div>
											</Form>
								</>
							)}
						</Formik>
					</Col>
				</Row>
			</Container>
		</div>
	</>
	);
};

export default About;
