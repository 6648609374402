import * as React from "react";
import {useAuth} from "../AuthUser";
import {Navigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import VideoUpload from "./VideoUpload";

function UploadVideos() {
	let auth = useAuth();

	if(!auth.user) {
		return <Navigate to="/signin" replace/>
	}

	return (
		<>
			<Helmet>
				<title>Upload Lesson Videos | mygolfdna.com</title>
				<meta name="description" content="upload lesson videos"/>
			</Helmet>
			<div className="mt-3">
				<Container>
					<Row>
						<Col sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 8, offset: 2 }}>
							<div className="hstack gap-3">
								<div className="h3">Upload Lesson Videos</div>
							</div>
							<hr/>
							<VideoUpload
							redirectTo="/dna-profile"/>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default UploadVideos;
