import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Card} from "react-bootstrap";
import PricingButton from "./components/PricingButton";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import API from "./api";
import Spinner from "react-bootstrap/Spinner";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function InstructorPricing() {
	const [plans, setPlans] = useState()
	let query = useQuery();
	let { instructorId } = useParams();
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setLoading(true);
			API.get(`plans/instructor/${instructorId}`)
				.then(response => {
					setPlans(response.data);
					return response.data;
				})
				.finally(() => setLoading(false));


		}, 0);
	}, [instructorId])


	const cadenceMap = {
		'MONTHLY': 'month',
		'ANNUAL': 'annual',
	}

	function insertDecimal(num) {
		return Number((num / 100).toFixed(2));
	}

	return (
		<>
			<Helmet>
				<title>Pricing | MyGolfDNA.com</title>
				<meta name="description" content="Pricing and Plans"/>
			</Helmet>
		<Container>
			<Row className="justify-content-center">
					<Col lg={5} md={12} sm={12}>
						<div className="" style={{padding:'56.25% 0 0 0',position:'relative'}}>
							<iframe
								src="https://player.vimeo.com/video/827001005?h=521d44fbfb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
								frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
								style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} title="Why mygolfdna and pocket pro mixed for sales page"/>
						</div>
					</Col>
					<h1 className="text-center text-white my-4" style={{fontSize: "3.75rem", lineHeight: 1, fontWeight: 800}}>Get Access Now</h1>
			</Row>
			<Row  className="justify-content-center">
					{isLoading ?
							<div className="p-4" style={{textAlign: 'center'}}> <Spinner className="text-orange" animation="border" /></div> :
						plans?.plans.map((plan, index) => (
						<Col lg={3} md={12} sm={12} key={index}>
							<Card className={`bg-dark ${plan.mostPopular ? 'border-secondary' : ''}`}>
								<Card.Body>
									<div className="d-grid gap-2">
										<Card.Title className={`${index === 1 ? 'text-secondary' : index === 2 ? 'text-white-50' : 'text-warning'} monument-font`}>{plan.planName}</Card.Title>
										<div className="text-white">
											<div dangerouslySetInnerHTML={{__html: plan.description}}>
											</div>
										</div>
										<div className="d-flex align-items-center gap-1">
											<div className="text-white h1">
												${insertDecimal(plan.amount)}
											</div>
											<small className="text-white">/{cadenceMap[plan.cadence]}</small>
										</div>
										<PricingButton
											href={`/plans/${plan.sourceId}`}
											plan={plan.sourceId}
										>
											Get Started Now
										</PricingButton>


									</div>
								</Card.Body>
							</Card>
						</Col>
						))}
			</Row>
		</Container>
			</>
	)
}

export default InstructorPricing;
