import {useAuth} from "../AuthUser";
import {Button} from "react-bootstrap";

const PricingButton = ({ href, plan }) => {
	let auth = useAuth();

	if (auth.user.planType === plan) {
		return <Button href={href} variant="light" disabled>Current Plan</Button>
	}
	if (auth.user && auth.user.planType !== plan) {
		return <Button href={href} variant="light" >Upgrade</Button>
	}
	return (<Button href={`/signup?r=${href}`} variant="light">Get Started Now</Button>)
};

export default PricingButton;

